<template>
  <div class="page">
    <div class="title">入职管理</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
        <!-- <div
                    class="custom_button plain no_select"
                    style="width: 145px;"
                >
                    入职登记表记录
                </div> -->
      </div>
    </div>

    <!-- 
            table
            @cell-click="onCellClick"
         -->
    <el-table
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="real_name" label="姓名"> </el-table-column>
      <el-table-column prop="department_data.department_name" label="入职部门">
      </el-table-column>
      <el-table-column prop="department_data.duty_name" label="入职岗位">
      </el-table-column>
      <!-- <el-table-column prop="expect_entry_time" label="预计入职时间"> -->
      <!-- </el-table-column> -->
      <el-table-column prop="status_value" label="入职状态"> </el-table-column>
      <!-- <el-table-column prop="status" label="入职登记"> </el-table-column> -->
      <el-table-column prop="mobile" label="手机号码"> </el-table-column>
      <!-- <el-table-column prop="channel_value" label="招聘渠道"> -->
      <!-- </el-table-column> -->
      <!-- <el-table-column prop="Latest" label="个人邮箱"> </el-table-column> -->
      <el-table-column label="操作" width="120">
        <template v-slot:default="scope">
          <el-dropdown split-button type="primary">
            操作
            <el-dropdown-menu
              size="mini"
              slot="dropdown"
              v-if="scope.row.status == 1"
            >
              <el-dropdown-item
                @click.native="
                  $router.push({
                    name: 'StandardContractsList',
                  })
                "
                >办理合同</el-dropdown-item
              >
            </el-dropdown-menu>

            <el-dropdown-menu size="mini" slot="dropdown" v-else>
              <!-- name: 'EntryManagementDetail',employeeDetail -->
              <!-- @click.native="
                  $router.push({
                    name: 'EntryManagementDetail',
                    params: { id: scope.row.id, isSave: true },
                  })
                " -->
              <el-dropdown-item
                @click.native="
                  $router.push({
                    name: 'EmployeeDetail',
                    params: { id: scope.row.id, isSave: true, Induction: true },
                  })
                "
                >确认入职</el-dropdown-item
              >
              <el-dropdown-item @click.native="toRefuse(scope.row.id)"
                >拒绝入职</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div class="operations">
						<div @click="toEntry(scope.row)">确认入职</div>
						<div @click="toRefuse(scope.row.id)" style="color: #4db9d5;">
							拒绝入职
						</div>
					</div> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <!-- <div class="bottom_betch_operation">
            <el-select class="betch_select" v-model="batch">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <div class="custom_button no_select" @click="toBatchProcessing">
				批量处理
			</div>
        </div> -->

    <el-dialog
      title="确定入职"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :inline="true" :model="form" :rules="rules">
        <el-form-item label="试用期（月）：" prop="probation_period">
          <el-input
            v-model="form.probation_period"
            type="number"
            :min="0"
            :max="6"
            style="width: 200px"
            @change="updatePeriodOptions"
            oninput="if(value.length>1)value=value.slice(0,1);if(value>6)value=6"
            onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          ></el-input>
        </el-form-item>
        <el-form-item label="合同期限：" prop="contract_period">
          <el-select v-model="form.contract_period" placeholder="请选择">
            <el-option
              v-for="item in period_options"
              :key="item.value"
              :label="item.value"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="试用期工资：" prop="pre_salary">
          <el-input v-model="form.pre_salary" type="number"></el-input>
        </el-form-item>

        <el-form-item label="转正工资：" prop="salary">
          <el-input v-model="form.salary" type="number"></el-input>
        </el-form-item>
        <br />
        <el-form-item
          label="社保："
          prop="is_social_security"
          style="width: 310px"
        >
          <el-radio-group
            v-model="form.is_social_security"
            @change="getLabourOptions"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="公积金：" prop="is_fund">
          <el-radio-group v-model="form.is_fund">
            <el-radio :label="1" :disabled="is_fund_disabled">是</el-radio>
            <el-radio :label="0" :disabled="is_fund_disabled">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item label="发薪城市：" prop="dataModel1">
          <el-cascader
            v-if="areaData1"
            v-model="form.dataModel1"
            placeholder="请选择城市"
            :options="areaData1"
          >
          </el-cascader>
        </el-form-item>

        <!-- 用工模式 -->
        <el-form-item label="用工模式：" prop="labour_type">
          <el-select
            v-model="form.labour_type"
            @change="getTaocanOptions"
            placeholder="请选择"
          >
            <el-option
              v-for="item in labour_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 入职时间 -->
        <el-form-item label="入职时间：" prop="entry_time">
          <el-date-picker
            v-model="form.entry_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="员工部门/职务：" prop="department">
          <el-cascader
            v-model="form.department"
            :options="department_options"
            collapse-tags
          ></el-cascader>
        </el-form-item>

        <el-form-item label="员工岗位：" prop="station_id">
          <el-select v-model="form.station_id" placeholder="请选择">
            <el-option
              v-for="item in station_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择套餐：" prop="taocan_id">
          <el-select v-model="form.taocan_id" placeholder="请选择">
            <el-option
              v-for="item in taocan_options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item>
          <el-checkbox v-model="is_print" :true-label="1" :false-label="0"
            >是否打印合同</el-checkbox
          >
          <span>(勾选后电子签不能发)</span>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" style="display: flex">
        <div
          class="custom_button no_select"
          style="margin-left: 10px"
          @click="addEmployee"
        >
          确 定
        </div>
      </span>
    </el-dialog>

    <el-dialog title="员工档案" :visible.sync="dialogVisible6">
      <el-form>
        <el-row style="display: flex; justify-content: space-between">
          <el-col :span="11">
            <el-form-item label="姓名">
              <el-input readonly v-model="employeeDetail.real_name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input readonly v-model="employeeDetail.sex"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-input readonly v-model="employeeDetail.birthday"></el-input>
            </el-form-item>
            <el-form-item :label="employeeDetail.cert_type || '居民身份证'">
              <el-input
                readonly
                v-model="employeeDetail.cert_number"
              ></el-input>
            </el-form-item>
            <el-form-item label="民族">
              <el-input readonly v-model="employeeDetail.nation"></el-input>
            </el-form-item>
            <el-form-item label="面貌">
              <el-input readonly v-model="employeeDetail.face"></el-input>
            </el-form-item>
            <el-form-item label="学历">
              <el-input readonly v-model="employeeDetail.edu"></el-input>
            </el-form-item>
            <el-form-item label="教育经历">
              <el-input
                readonly
                type="textarea"
                v-model="employeeDetail.edu_exp"
              ></el-input>
            </el-form-item>
            <el-form-item label="参加工作时间">
              <el-input readonly v-model="employeeDetail.work_time"></el-input>
            </el-form-item>
            <el-form-item label="工作经历">
              <el-input
                readonly
                type="textarea"
                v-model="employeeDetail.job_exp"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否已婚">
              <el-input
                readonly
                type="textarea"
                v-model="employeeDetail.is_marry"
              ></el-input>
              <!-- <el-checkbox
                disabled
                v-model="employeeDetail.is_marry"
              ></el-checkbox> -->
            </el-form-item>
            <el-form-item label="是否已育">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_birth"
              ></el-checkbox>
            </el-form-item>
            <el-form-item label="是否首次缴纳社保">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_first_social_security"
              ></el-checkbox>
            </el-form-item>
            <el-form-item v-if="employeeDetail.social_security" label="社保号">
              <el-input
                readonly
                v-model="employeeDetail.social_security"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否首次缴纳公积金">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_first_accumulation_fund"
              ></el-checkbox>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.accumulation_fund"
              label="公积金"
            >
              <el-input
                readonly
                v-model="employeeDetail.accumulation_fund"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="户籍">
              <el-input
                readonly
                v-model="employeeDetail.household_registration"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input readonly v-model="employeeDetail.address"></el-input>
            </el-form-item>
            <el-form-item label="户口性质">
              <el-input
                readonly
                v-model="employeeDetail.residence_type"
              ></el-input>
            </el-form-item>
            <el-form-item label="现居地址">
              <el-input
                readonly
                v-model="employeeDetail.current_residence"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input readonly v-model="employeeDetail.mobile"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input readonly v-model="employeeDetail.email"></el-input>
            </el-form-item>
            <el-form-item label="QQ">
              <el-input readonly v-model="employeeDetail.qq"></el-input>
            </el-form-item>
            <el-form-item label="微信">
              <el-input readonly v-model="employeeDetail.wechat"></el-input>
            </el-form-item>
            <el-form-item label="银行名称">
              <el-input readonly v-model="employeeDetail.bank_name"></el-input>
            </el-form-item>
            <el-form-item label="银行卡号">
              <el-input readonly v-model="employeeDetail.bank_no"></el-input>
            </el-form-item>
            <el-form-item label="开户银行">
              <el-input
                readonly
                v-model="employeeDetail.bank_deposit"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否有重大疾病">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_major_diseases"
              ></el-checkbox>
            </el-form-item>
            <el-form-item
              label="重大疾病说明"
              v-if="employeeDetail.major_diseases"
            >
              <el-input
                readonly
                v-model="employeeDetail.major_diseases"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否有残疾">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_disability"
              ></el-checkbox>
            </el-form-item>
            <el-form-item label="残疾说明" v-if="employeeDetail.disability">
              <el-input readonly v-model="employeeDetail.disability"></el-input>
            </el-form-item>
            <el-form-item label="是否有职业病">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_occupation_diseases"
              ></el-checkbox>
            </el-form-item>
            <el-form-item
              label="职业病说明"
              v-if="employeeDetail.occupation_diseases"
            >
              <el-input
                readonly
                v-model="employeeDetail.occupation_diseases"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否从事过重体力劳动或有害工种">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_harmful"
              ></el-checkbox>
            </el-form-item>
            <el-form-item
              label="重体力劳动或有害工种说明"
              v-if="employeeDetail.harmful"
            >
              <el-input readonly v-model="employeeDetail.harmful"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row style="display: flex; justify-content: space-between">
          <el-col :span="11">
            <el-form-item
              v-if="employeeDetail.certifity_expired"
              label="身份证有效期"
            >
              <el-input
                readonly
                v-model="employeeDetail.certifity_expired"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.idcard_front_url"
              label="身份证正面"
            >
              <el-image
                :src="employeeDetail.idcard_front_url"
                fit="contain"
                :preview-src-list="[employeeDetail.idcard_front_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.idcard_back_url"
              label="身份证反面"
            >
              <el-image
                :src="employeeDetail.idcard_back_url"
                fit="contain"
                :preview-src-list="[employeeDetail.idcard_back_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item v-if="employeeDetail.edu_prove_url" label="学历证明">
              <el-image
                :src="employeeDetail.edu_prove_url"
                fit="contain"
                :preview-src-list="[employeeDetail.edu_prove_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              v-if="employeeDetail.personal_img_url"
              label="个人证照"
            >
              <el-image
                :src="employeeDetail.personal_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.personal_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.social_card_img_url"
              label="社保卡"
            >
              <el-image
                :src="employeeDetail.social_card_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.social_card_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.fund_card_img_url"
              label="公积金卡"
            >
              <el-image
                :src="employeeDetail.fund_card_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.fund_card_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-form-item label="紧急联系人">
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item label="姓名">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_name"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_phone"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="住址">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_address"
                ></el-input>
              </el-form-item>
              <el-form-item label="关系">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_relate"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="custom_button no_select" @click="dialogVisible6 = false">
          确 定
        </div>
      </span>
    </el-dialog>

    <el-dialog
      title="合同列表"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-table :data="fddTemplateList">
        <el-table-column property="title" label="合同名称"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template v-slot:default="scope">
            <el-button
              @click="toPrint(scope.row.template_id)"
              type="text"
              size="small"
              >打印</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AreaJson1 from "@/util/area-full1.json";

export default {
  data() {
    const checkArray = (rule, value, callback) => {
      if (value.length < 2) return callback(new Error("请选择员工部门/职务"));
      callback();
    };
    return {
      search: {
        keyword: "",
        status: 1,
      },
      status_options: [
        {
          value: 1,
          label: "待确认",
        },
        {
          value: 2,
          label: "已确认入职",
        },
      ],
      areaData1: null,
      query_options: [],
      department_options: [],
      station_options: [],
      taocan_options: [],
      labour_options: [],
      tableData: [],
      dialogVisible: false,
      query_loading: false,
      dialogVisible6: false,
      dialogTableVisible: false,
      employeeDetail: {},
      contract_options: [], //合同列表
      period_options: [
        {
          value: 1,
          disabled: false,
        },
        {
          value: 2,
          disabled: false,
        },
        {
          value: 3,
          disabled: false,
        },
        {
          value: 4,
          disabled: false,
        },
        {
          value: 5,
          disabled: false,
        },
        {
          value: 6,
          disabled: false,
        },
        {
          value: 7,
          disabled: false,
        },
        {
          value: 8,
          disabled: false,
        },
        {
          value: 9,
          disabled: false,
        },
        {
          value: 10,
          disabled: false,
        },
        {
          value: "无固定期限",
          disabled: false,
        },
      ],
      is_fund_disabled: true,
      is_print: 0,
      form: {
        probation_period: 0,
        contract_period: "",
        pre_salary: "",
        salary: "",
        is_social_security: 0,
        is_fund: 0,
        dataModel1: "", //del
        department: [], //del
        department_id: "",
        duty_id: "",
        station_id: "",
        taocan_id: "", //del
        entry_time: "",
        labour_type: "",
      },
      selectedId: "",
      rules: {
        probation_period: [
          {
            required: true,
            message: "请填写试用期",
            trigger: "blur",
          },
        ],
        pre_salary: [
          {
            required: true,
            message: "请填写试用期工资",
            trigger: "blur",
          },
        ],
        contract_period: [
          {
            required: true,
            message: "请选择合同期限",
            trigger: "change",
          },
        ],
        salary: [
          {
            required: true,
            message: "请填写转正工资",
            trigger: "blur",
          },
        ],
        is_social_security: [
          {
            required: true,
          },
        ],
        is_fund: [
          {
            required: true,
          },
        ],
        dataModel1: [
          {
            required: true,
            message: "请选择发薪城市",
            trigger: "change",
          },
        ],
        department: [
          {
            required: true,
            validator: checkArray,
            trigger: "change",
          },
        ],
        station_id: [
          {
            required: true,
            message: "请选择员工岗位",
            trigger: "change",
          },
        ],
        taocan_id: [
          {
            required: true,
            message: "请选择套餐",
            trigger: "change",
          },
        ],
        entry_time: [
          {
            required: true,
            message: "请选择员工入职时间",
            trigger: "change",
          },
        ],
        labour_type: [
          {
            required: true,
            message: "请选择用工类型",
            trigger: "change",
          },
        ],
      },
      batch: 1,
      options: [
        {
          value: 1,
          label: "确认入职",
        },
        {
          value: 2,
          label: "拒绝入职",
        },
      ],
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
      fddTemplateList: [],
    };
  },
  watch: {
    "form.dataModel1"(newVal) {
      this.form.salary_province = newVal[0];
      this.form.salary_city = newVal[1];
    },
  },
  created() {
    let areaData1 = AreaJson1;
    console.log(areaData1);
    for (var i = 0; i < areaData1.length; i++) {
      let prov1 = areaData1[i];
      for (let j = 0; j < prov1.children.length; j++) {
        let city1 = prov1.children[j];
        if (city1.children) delete city1.children;
      }
    }
    this.areaData1 = areaData1;

    this.getDepartmentList()
      .then((res) => {
        console.log(res, "测试");
        this.department_options = res.data.data.list.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
            children: item.child.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            }),
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    this.getStationList()
      .then((res) => {
        console.log(res);
        this.station_options = res.data.data.list;
      })
      .catch((e) => {
        console.log(e);
      });
    /**
     * 获取合同
     */
    this.getStandardContractList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.contract_options = res.data.data.list.map((item) => {
          return {
            label: item.title,
            value: item.template_id,
          };
        });
      })
      .catch((e) => {
        this.$message.error(e.msg);
      });

    this.loadData = this.loadDataFactory();
    this.loadData();
  },

  methods: {
    ...mapActions("employee", [
      "getEmployeeEntryList",
      "passEmployeeEntry",
      "getDepartmentList",
      "getStationList",
      "getLabourTypeData",
      "getFddTemplateCate",
      "getFddTemplate",
      "getFddTemplateList",
      "getEmployeeRosterDetail",
      "getBlackList",
      "postRefuseRegular",
    ]),
    ...mapActions("contract", ["postBatchSign", "getStandardContractList"]),

    /**
     * 入职
     */
    async toEntry(employee) {
      console.log(employee);
      this.selectedId = employee.id;
      this.selectedFddId = employee.fddUserInfo?.id;
      if (!this.selectedFddId) {
        this.$alert("该员工未注册法大大", "提示", {
          confirmButtonText: "确定",
          type: "warning",
        });
      }
      // 黑名单检测
      try {
        await this.getBlackList({
          ids: this.selectedId,
        });
        this.dialogVisible = true;
        this.getLabourOptions();
      } catch (e) {
        console.error(e);
        this.$alert(e.msg, "黑名单", {
          confirmButtonText: "确定",
          type: "warning",
        }).then(() => {
          this.dialogVisible = true;
        });
      }
    },
    // 更新合同期限
    updatePeriodOptions(val) {
      this.form.contract_period = "";
      this.period_options = this.period_options.map((item) => {
        let disabled = false;
        if (item.value < val) {
          disabled = true;
        }
        return {
          value: item.value,
          disabled: disabled,
        };
      });
      console.log(JSON.stringify(this.period_options));
    },
    // 获取用工模式
    getLabourOptions(val) {
      console.log("getLabourOptions", val);
      if (val == 1) {
        this.is_fund_disabled = false;
      } else {
        this.is_fund_disabled = true;
        this.form.is_fund = 0;
      }
      this.form.labour_type = "";
      this.form.taocan_id = "";
      let params = {};
      params.staff_id = this.selectedId;
      params.is_social_security = val;
      this.getLabourTypeData(params)
        .then((res) => {
          console.log(res);
          this.labour_options = Object.keys(res.data.data.labour_type).map(
            (item) => {
              return {
                id: item,
                name: res.data.data.labour_type[item],
              };
            }
          );
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getTaocanOptions(val) {
      this.getFddTemplateCate({
        staff_id: this.selectedId,
        is_social_security: this.form.is_social_security,
        labour_type: val,
      })
        .then((res) => {
          console.log(res);
          this.taocan_options = res.data.data.list;
          if (res.data.data.list.length == 1) {
            this.form.taocan_id = res.data.data.list[0].id;
          } else {
            this.form.taocan_id = "";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword.trim();
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getEmployeeEntryList(params)
          .then((res) => {
            console.log("EntryManagement", res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 查看员工档案
     */
    onCellClick(row, column, cell, event) {
      console.log(row, column, cell, event);
      if (cell.cellIndex == 9) return;
      let loading = this.$loading();
      this.getEmployeeRosterDetail({
        staff_id: row.id,
      })
        .then((res) => {
          console.log(res);
          this.employeeDetail = res.data.data.detail;
          if (!this.employeeDetail) {
            this.$message.error("该用户信息为空");
          } else {
            // 数据处理
            // this.employeeDetail.is_marry = this.employeeDetail.is_marry == 1;
            this.employeeDetail.is_birth = this.employeeDetail.is_birth == 1;
            this.employeeDetail.is_major_diseases =
              this.employeeDetail.is_major_diseases == 1;
            this.employeeDetail.is_disability =
              this.employeeDetail.is_disability == 1;
            this.employeeDetail.is_occupation_diseases =
              this.employeeDetail.is_occupation_diseases == 1;
            this.employeeDetail.is_harmful =
              this.employeeDetail.is_harmful == 1;
            let household_registration = "";
            if (this.employeeDetail.province)
              household_registration += this.employeeDetail.province;
            if (this.employeeDetail.city)
              household_registration += this.employeeDetail.city;
            if (this.employeeDetail.area)
              household_registration += this.employeeDetail.area;
            this.employeeDetail.household_registration = household_registration;
            if (this.employeeDetail.edu_exp.length > 0)
              this.employeeDetail.edu_exp = this.employeeDetail.edu_exp
                .map((item) => {
                  return `${item.school} ${item.degree} ${item.major}\n${item.entry_date}-${item.leave_date}`;
                })
                .join("\n");
            if (this.employeeDetail.job_exp.length > 0)
              this.employeeDetail.job_exp = this.employeeDetail.job_exp
                .map((item) => {
                  return `${item.company} ${item.department} ${item.position} ${item.industry}\n${item.entry_date}-${item.leave_date}\n${item.work_desc}`;
                })
                .join("\n");

            // 打开弹窗
            this.dialogVisible6 = true;
          }
          loading.close();
        })
        .catch((e) => {
          console.error(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },

    /**
     * 批量处理
     */
    // toBatchProcessing() {
    // 	let selectedItems = this.$refs.table.store.states.selection;
    // 	console.log(selectedItems);
    // 	if (selectedItems.length > 0) {
    // 		switch (this.batch) {
    // 			case 1:
    // 				this.toEntry(selectedItems);
    // 				break;

    // 			case 2:
    // 				this.toRefuse(selectedItems.map((item) => item.id));
    // 				break;

    // 			default:
    // 				break;
    // 		}
    // 	} else {
    // 		this.$message.warning("请至少选择一项进行操作");
    // 	}
    // },
    /**
     * 确定入职
     */
    addEmployee() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("addEmployee", this.form);
          this.form.ids = this.selectedId;
          let loading = this.$loading();

          this.form.department_id = this.form.department[0];
          this.form.duty_id = this.form.department[1];

          this.passEmployeeEntry(this.form)
            .then((res) => {
              console.log("passEmployeeEntry", res);
              this.$message.success("信息提交成功");
              if (this.is_print == 1) {
                this.showHeTong();
              }
              if (this.is_print == 0) {
                this.postBatchSign({
                  template_ids: this.taocan_options
                    .find((item) => item.id == this.form.taocan_id)
                    .template_ids.join(","),
                  party_b_fdd_userids: this.selectedFddId,
                })
                  .then((res) => {
                    console.log(res);
                    loading.close();
                    this.$message.success(res.data.msg);
                    this.dialogVisible = false;
                    this.loadData();
                  })
                  .catch((e) => {
                    loading.close();
                    console.log(e);
                    this.$message.error(e.msg);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error("信息提交失败", e);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    toRefuse(id) {
      this.$confirm("确定拒绝入职吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let loading = this.$loading();
        this.postRefuseRegular({
          id,
        })
          .then((res) => {
            console.log(res);
            loading.close();
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.log(e);
            loading.close();
            this.$message.error(e.msg);
          });
      });
    },
    showHeTong() {
      let params = {};
      params.id = this.form.taocan_id;
      params.staff_id = this.selectedId;
      this.getFddTemplateList(params)
        .then((res) => {
          console.log(res);
          this.fddTemplateList = res.data.data.list;
          this.dialogTableVisible = true;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    toPrint(template_id) {
      let params = {};
      params.staff_id = this.selectedId;
      params.template_id = template_id;
      this.getFddTemplate(params)
        .then((res) => {
          window.open(res.data.data.full_link, "_blank");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    close() {
      this.$confirm("您是否打印完所有合同，确认关闭弹窗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.dialogTableVisible = false;
          this.dialogVisible = false;
          this.loadData();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 130px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}

.bottom_betch_operation {
  position: absolute;
  bottom: 0;
  display: flex;

  .betch_select {
    width: 130px;
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }

    ::v-deep .el-input__icon {
      line-height: 36px;
    }
  }
}
</style>
